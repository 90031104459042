import React, { memo } from 'react';
import { Link } from 'react-router-dom';

/** Styles */
import './index.css';

/** Components */
import { logout as LOGOUT } from '../../../services/api/mutationService';
import { useMutation, useQuery } from 'react-apollo';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  me as PROFILE,
} from '../../../services/api/queryService';
import { useEffect } from 'react';

const TopBar = () => {

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const [logout, { loading }] = useMutation(LOGOUT);

  const [refreshBtnClicked, setRefreshBtnClicked] = useState(false)

  const { loading: profileLoading, data, error } = useQuery(PROFILE);

  useEffect(() => {
    console.log(data)

    if (data) {
      if (data.me) {
        const userData = { ...data.me };
       
        dispatch({
          type: 'PROFILE',
          payload: userData
        });

        const checkIfLogged = localStorage.getItem('loggedIn');
        let payload = {}
        if(checkIfLogged === null){
          payload = {
            type: 'success',
            message: ` Welcome, ${data.me.username}`
          }

          localStorage.setItem("loggedIn", 1);
        }

        dispatch({
          type: 'UPDATE',
          payload: payload
        });



      } else {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('loggedIn')
        window.location.href = '/login';
      }
    }
  }, [data, profileLoading, error, dispatch]);


  const logoutHandler = e => {
    e.preventDefault();

    const authToken = localStorage.getItem('auth_token');

    if (authToken) {
      logout({ variable: { apiToken: authToken } });

      if (!loading) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('loggedIn')
        window.location.href = '/login';
      }
    }
  };

  return (
    <>
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/"><img src="/assets/images/logo.svg" alt="logo"/></Link>
          <Link className="navbar-brand brand-logo-mini" to="/"><img src="../../images/logo-mini.svg" alt="logo"/></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            {/* <li className="nav-item dropdown">
              <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                <i className="mdi mdi-bell-outline mx-0"></i>
                <span className="count"></span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                <a className="dropdown-item">
                  <p className="mb-0 font-weight-normal float-left">You have 4 new notifications
                  </p>
                  <span className="badge badge-pill badge-warning float-right">View all</span>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-information mx-0"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-medium">Application Error</h6>
                    <p className="font-weight-light small-text mb-0">
                      Just now
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings mx-0"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-medium">Settings</h6>
                    <p className="font-weight-light small-text mb-0">
                      Private message
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-account-box mx-0"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject font-weight-medium">New user registration</h6>
                    <p className="font-weight-light small-text mb-0">
                      2 days ago
                    </p>
                  </div>
                </a>
              </div>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a className="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-email-outline mx-0"></i>
                <span className="count"></span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                <div className="dropdown-item">
                  <p className="mb-0 font-weight-normal float-left">You have 7 unread mails
                  </p>
                  <span className="badge badge-info badge-pill float-right">View all</span>
                </div>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                      <img src="https://via.placeholder.com/36x36" alt="image" className="profile-pic" />
                  </div>
                  <div className="preview-item-content flex-grow">
                    <h6 className="preview-subject ellipsis font-weight-medium">David Grey
                      <span className="float-right font-weight-light small-text">1 Minutes ago</span>
                    </h6>
                    <p className="font-weight-light small-text mb-0">
                      The meeting is cancelled
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                      <img src="https://via.placeholder.com/36x36" alt="image" className="profile-pic" />
                  </div>
                  <div className="preview-item-content flex-grow">
                    <h6 className="preview-subject ellipsis font-weight-medium">Tim Cook
                      <span className="float-right font-weight-light small-text">15 Minutes ago</span>
                    </h6>
                    <p className="font-weight-light small-text mb-0">
                      New product launch
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                      <img src="https://via.placeholder.com/36x36" alt="image" className="profile-pic" />
                  </div>
                  <div className="preview-item-content flex-grow">
                    <h6 className="preview-subject ellipsis font-weight-medium"> Johnson
                      <span className="float-right font-weight-light small-text">18 Minutes ago</span>
                    </h6>
                    <p className="font-weight-light small-text mb-0">
                      Upcoming board meeting
                    </p>
                  </div>
                </a>
              </div>
            </li> */}
            <li className="nav-item nav-profile dropdown">
              <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                { user.username }
                <img style={{marginLeft: '10px'}} src={ user.image??'https://via.placeholder.com/30x30' } alt=""/>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                <a className="dropdown-item">
                  <i className="mdi mdi-settings text-primary"></i>
                  Settings
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href='#' onClick={logoutHandler}>
                  <i className="mdi mdi-logout text-primary"></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      {/* end Topbar */}
    </>
  );
};

export default memo(TopBar);
