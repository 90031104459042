import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import Calendar from 'react-calendar';

import Autocomplete from "react-google-autocomplete";
/** Components */

import 'react-calendar/dist/Calendar.css';
import { updateEvent } from '../../services/api/mutationService';
import { useMutation, useQuery } from 'react-apollo';
import Axios from 'axios';
import { urlApi } from '../../App';
import { categories, getMyEvent, subcategories } from '../../services/api/queryService';
import { convertDataToOptions, createDefaultOption } from '../../services/util/dataHandlerService';

const EditEvent = ({ admin }) => {
    const [formData, setFormData] = useState({})
    const [address, setAddress] = useState({})

    const [categoriesList, setCategoriesList] = useState([])
    const [subcategoriesList, setSubcategoriesList] = useState([])


    const { data: categoriesData, loading: categoriesLoading } = useQuery(categories);
    const { data: subcategoriesData, loading: subcategoriesLoading } = useQuery(subcategories);

    useEffect(() => {
        if(categoriesData){
            setCategoriesList(categoriesData.categories)
        }

        if(subcategoriesData){
            setSubcategoriesList(subcategoriesData.subcategories)
        }

    }, [categoriesLoading, subcategoriesLoading])

    const dispatch = useDispatch()
    const history = useHistory();
    const location = useLocation();
    
    const { data: event, loading: eventLoading, refetch } = useQuery(getMyEvent, {
        variables: { id: location.pathname.split('/')[3] }
    });

    const [updateEventMutation, { loading, data, error }] = useMutation(updateEvent);

    const [showCalendar, setShowCalendar] = useState(false)
    const [dateValue, setDateValue] = useState(false)
    const [gallery, setGallery] = useState([])
    const [galleryFiles, setGalleryFiles] = useState([])
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)

    const imageRef = useRef(null)
    const galleryRef = useRef(null)
    const videoRef = useRef(null)


    
    useEffect(() => {
        if(event){
            setFormData({
                id: event.getMyEvent.id,
                title: event.getMyEvent.title,
                description: event.getMyEvent.description,
                date: event.getMyEvent.date,
                time: event.getMyEvent.time,
                category_id: event.getMyEvent.category.id,
                subcategory_id: event.getMyEvent.subcategory?.id,
                lat: event.getMyEvent.lat,
                lng: event.getMyEvent.lng,
                address: event.getMyEvent.address,
                image: event.getMyEvent.image,
                link: event.getMyEvent.link,
                is_free: event.getMyEvent.is_free,
                is_online: event.getMyEvent.is_online,
            })
            setGallery([...event.getMyEvent.gallery])
            setAddress({
                lat: event.getMyEvent.lat,
                lng: event.getMyEvent.lng,
                address: event.getMyEvent.address,
            })
        }

        refetch()
    
    }, [eventLoading, event])

    const changeFieldHandler = event => {
        const fd = {...formData}
        if(event.target.id == 'is_free'){
            fd.is_free = event.target.value == 'false' ? 0 : 1
        }else if(event.target.id == 'is_online'){
            fd.is_online = event.target.value == 'false' ? 0 : 1
        }else{
            fd[event.target.id] = event.target.value
        }

        setFormData({...fd});
      
        setErrors({})
    };

    const changeDescription = val => {
        let fd = {}
        if(Object.keys(formData).length == 0){
            fd = {
                id: event.getMyEvent.id,
                title: event.getMyEvent.title,
                description: event.getMyEvent.description,
                date: event.getMyEvent.date,
                time: event.getMyEvent.time,
                lat: event.getMyEvent.lat,
                lng: event.getMyEvent.lng,
                category_id: event.getMyEvent.category.id,
                subcategory_id: event.getMyEvent.subcategory?.id,
                lat: event.getMyEvent.lat,
                lng: event.getMyEvent.lng,
                address: event.getMyEvent.address,
                image: event.getMyEvent.image,
                link: event.getMyEvent.link,
                is_free: event.getMyEvent.is_free,
                is_online: event.getMyEvent.is_online,
            }
        }else{
            fd = {...formData}
        }

        fd['description'] = val

        setFormData({...fd});
        setErrors({})
    }

    const changeDate = val => {
        let d = new Date(val).getDate()
        let m = new Date(val).getMonth() + 1
        let y = new Date(val).getFullYear()

        if(d < 10){
            d = `0${d}`
        }

        if(m < 10){
            m = `0${m}`
        }

        setFormData({
            ...formData,
            ['date']: `${d}/${m}/${y}`
        });

        setDateValue(val)
        setShowCalendar(false)
    }

    const imageHandle = event => {
        console.log(event.target.files)

        setFormData({
            ...formData,
            ['hasImage']: true
        });
    
        setErrors({})
    }

    const multipleImageHandle = event => {
        console.log(Object.keys(event.target.files))
        const images = [...gallery]
        const galleryArray = [...galleryFiles]
        Object.keys(event.target.files).map((file, i) => {
            images.push(URL.createObjectURL(event.target.files[i]))
            galleryArray.push(event.target.files[i])
        })
        
        setGalleryFiles([...galleryArray])

        setGallery([...images])
        // URL.createObjectURL
    }

    const removeGalleryImage = index => {
        const g = [...gallery]
        g.splice(index, 1)
        const galleryArray = [...galleryFiles]
        galleryArray.splice(index, 1)


        setGalleryFiles([...galleryArray])
        setGallery([...g])
    }

    const changeAddress = place => {
        console.log(address, place)
        const fd = {...address}
        fd['lat'] = place.geometry.location.lat()
        fd['lng'] = place.geometry.location.lng()
        fd['address']  = place.formatted_address

        setErrors({})
        setAddress({...fd});
    }

    const changeSelectHandler = (option, data) => {
        setFormData({
          ...formData,
          [data.name]: option && +option.value
        });
    };

    const videoHandle = event => {

        const fd = {...formData}

        Object.keys(event.target.files).map((file, i) => {
            fd.video = URL.createObjectURL(event.target.files[i])
            fd.hasVideo = true
            fd.videoFile = event.target.files[i]
        })

        setFormData({...fd});
    
        setErrors({})
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {...formData, ...address}
        const errs = {}

        if(!payload.title){
            errs.title = 'სათაური აუცილებელია'
        }else if(payload.title.length < 4){
            errs.title = 'სათაური უნდა შეიცავდე მინ. 4 სიმბოლოს'
        }

        if(!payload.description){
            errs.description = 'აღწერა აუცილებელია'
        }else if(payload.description.length < 10){
            errs.description = 'აღწერა უნდა შეიცავდე მინ. 10 სიმბოლოს'
        }

        if(!payload.category_id){
            errs.category_id = 'კატეგორია აუცილებელია'
        }


        if(!payload.date){
            errs.date = 'თარიღი აუცილებელია'
        }
        
        if(!payload.time){
            errs.time = 'დრო აუცილებელია'
        }

        if(!payload.address && !!(!formData.payload)){
            errs.address = 'მისამართი აუცილებელია'
        }
        

        if(Object.keys(errs).length !== 0){
            setErrors({...errs})

            setLoader(false)

        }else{

            delete payload.hasImage
            delete payload.image
            delete payload.video
            delete payload.hasVideo
            delete payload.videoFile

            if(imageRef.current.files.length == 0 && galleryFiles.length == 0){
                payload.gallery = gallery
            }

            const response = await updateEventMutation({
                variables: { input: payload }
            })


            if(response && response.data.updateEvent){
                const fData = new FormData();
                if(imageRef.current.files.length > 0){
                    fData.append(`image`, imageRef.current.files[0])
                }

                if(formData.videoFile){
                    fData.append(`video`, formData.videoFile)
                }


                galleryFiles.map((x, i) => {
                    fData.append(`gallery${i}`, x)
                })

                fData.append(`event_id`, response.data.updateEvent.id)

                
                if(imageRef.current.files.length == 0 && !formData.videoFile &&  galleryFiles.length == 0){
                    setLoader(false)

                    dispatch({
                        type: 'UPDATE',
                        payload: {
                          type: 'success',
                          message: 'ივენთი წარმატებით დარედაქტირდა'
                        }
                    });

                    return history.push(`/my-events`);
                }

                Axios.post(`${urlApi}/api/image-upload`, fData)
                .then(res => {
                    setLoader(false)
                    
                    if(res.data.status == 200){
                        dispatch({
                            type: 'UPDATE',
                            payload: {
                              type: 'success',
                              message: 'ივენთი წარმატებით დარედაქტირდა'
                            }
                        });

                        return history.push(`/my-events`);
                    }else{
                        dispatch({
                            type: 'UPDATE',
                            payload: {
                              type: 'error',
                              message: 'დამატების დროს დაფიქსირდა შეცდომა'
                            }
                        });
                    }

                })
            }
        }
    }



    return (
        <>
            <div className="content-wrapper">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <Link to="/my-events" className="btn btn-secondary btn-icon-text btn-sm">
                                <span className="mdi   mdi-arrow-left"></span> უკან დაბრუნება
                            </Link>
                        </div>
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">ივენთის დამატება</h4>
                                    <form className="forms-sample" autoComplete='off' onSubmit={submitHandler}>
                                        <div className="form-group ">
                                            <label >მთავარი სურათი <span style={{color:'red'}}>*</span></label>
                                            <img src={formData.image} alt="" style={{ maxWidth: '400px', maxHeight: '350px', marginBottom: '25px', objectFit: 'contain', display: 'block' }} />

                                            <div className="col-4">
                                                <input type="file" className="dropify" onChange={imageHandle} ref={imageRef} />
                                            </div>
                                            {
                                                errors.image && (
                                                    <p className="text-danger p-1">{errors.image}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group ">
                                            <label >გალერეა</label>
                                            <div className="col-8">
                                                <input type="file" onChange={multipleImageHandle} ref={galleryRef} multiple max={6} />

                                                <div className="galleryPreview" style={{ display: 'flex', flexWrap: 'wrap', margin: '35px 0' }}>
                                                    { gallery.length > 0 && gallery.map((img, index) => (
                                                        <div className="galleryItem" style={{ position: 'relative' }}>
                                                            <img src={img} alt="" style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px', objectFit: 'contain' }} />
                                                            <i style={{ position: 'absolute', top: '-20px', right: '0', color: 'red', fontSize: '24px', cursor: 'pointer' }} onClick={() => removeGalleryImage(index)} className='mdi mdi-close-box'></i>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                        {admin && (

                                            <div className="form-group ">
                                                <label >პრომო ვიდეო </label>
                                                <div className="col-6 mt-3">

                                                    { formData && formData.video && (
                                                        <video  controls style={{ maxWidth: '400px', maxHeight: '280px', margin: '5px', objectFit: 'contain', display: 'block' }} >
                                                            <source src={formData.video} type="video/mp4" />
                                                        </video>  
                                                    )}

                                                    <input type="file" onChange={videoHandle} ref={videoRef} />
                                                </div>
                                                {
                                                    errors.image && (
                                                        <p className="text-danger p-1">{errors.image}</p>
                                                    )
                                                }
                                            </div>
                                        )}

                                        <div class="form-group col-4 px-0">
                                            <label>აირჩიეთ კატეგორია</label>
                                            <Select
                                                name="category_id"
                                                placeholder="კატეგორია"
                                                options={convertDataToOptions(categoriesList)}
                                                value={createDefaultOption(
                                                    categoriesList.find(i => i.id === formData.category_id)
                                                )}
                                                onChange={changeSelectHandler}
                                            />
                                            {
                                                errors.category_id && (
                                                    <p className="text-danger p-1">{errors.category_id}</p>
                                                )
                                            }
                                        </div>
                                        {admin && (
                                            <div class="form-group col-4 px-0">
                                                <label>აირჩიეთ ქვეკატეგორია</label>
                                                <Select
                                                    name="subcategory_id"
                                                    placeholder="ქვეკატეგორია"
                                                    options={convertDataToOptions(formData.category_id ? subcategoriesList.filter(x => +x.category_id == +formData.category_id) : [])}
                                                    value={createDefaultOption(
                                                        subcategoriesList.find(i => i.id === formData.subcategory_id)
                                                    )}
                                                    onChange={changeSelectHandler}
                                                />
                                                {
                                                    errors.subcategory_id && (
                                                        <p className="text-danger p-1">{errors.subcategory_id}</p>
                                                    )
                                                }
                                            </div>
                                        )}
                                        <div className="form-group">
                                            <label >სათაური <span style={{color:'red'}}>*</span></label>
                                            <input  type="text" className="form-control" id="title" placeholder="სათაური" onChange={changeFieldHandler} value={formData.title} />
                                            {
                                                errors.title && (
                                                    <p className="text-danger p-1">{errors.title}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >აღწერა <span style={{color:'red'}}>*</span></label>
                                            <ReactQuill theme="snow" id='description' value={formData.description??''} onChange={(e) => changeDescription(e)} />
                                            {
                                                errors.description && (
                                                    <p className="text-danger p-1">{errors.description}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >მისამართი <span style={{color:'red'}}>*</span></label>
                                            <Autocomplete
                                                apiKey={'AIzaSyCi2P4n_UUInGZx7EARrCPMxdTQaEeYQAw'}
                                                className="form-control"
                                                onPlaceSelected={(place, ref) => {
                                                    changeAddress(place)
                                                }}
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                                defaultValue={address.address}
                                                id='address'
                                                language='ka'
                                                placeholder='მაგ: 88 წერეთლის გამზ...'
                                                autoComplete='off'
                                            />
                                            {
                                                errors.address && (
                                                    <p className="text-danger p-1">{errors.address}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >თარიღი <span style={{color:'red'}}>*</span></label>
                                            <input type="text" className="form-control col-4" id="date" value={formData.date} onClick={() => setShowCalendar(true)}/>
                                            {showCalendar && <Calendar onChange={changeDate} value={dateValue} minDate={new Date()} />}
                                            {
                                                errors.date && (
                                                    <p className="text-danger p-1">{errors.date}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >დრო <span style={{color:'red'}}>*</span></label>
                                            <input className="form-control col-4" type='time' id='time' value={formData.time} onChange={changeFieldHandler} />
                                            {
                                                errors.time && (
                                                    <p className="text-danger p-1">{errors.time}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >ლინკი</label>
                                            <input  type="text" className="form-control col-6" id="link" placeholder="ლინკი" onChange={changeFieldHandler} value={formData.link} />
                                        </div>

                                        <div className="form-group">
                                            <div class="form-check form-check-flat form-check-primary">
                                                <label class="cursor-pointer">
                                                    უფასო
                                                    <input type="checkbox" className='ml-3' id='is_free' onChange={changeFieldHandler} checked={!!formData.is_free}  value={!formData.is_free} />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div class="form-check form-check-flat form-check-primary">
                                                <label class="cursor-pointer">
                                                    Online
                                                    <input type="checkbox" className='ml-3' id='is_online' onChange={changeFieldHandler} checked={!!formData.is_online}  value={!formData.is_online} />
                                                </label>
                                            </div>
                                        </div>

                                        {loader ? (
                                            <div class="circle-loader"></div>
                                        ) : (
                                            <button type="submit" className="btn btn-primary mr-2">რედაქტირება</button>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <script src='/assets/main.js'>
                
            </script>
        </>
    );
};

export default EditEvent;
