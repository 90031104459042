import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Apollo */
import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
/** layout */
import TopBar from './components/layout/topBar';
import SideBar from './components/layout/SideBar';
import Footer from './components/layout/Footer';

/** Actual Pages */
import Auth from './view/auth/Auth';
import Notification from './components/notification/Notification';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import Fallback from './components/error-boundary/Fallback';

/** libs */
import { PrivateRoutes } from './lib/hoc/PrivateRoutes';
import routes from './lib/constants/routes';
import Register from './view/auth/Register';
import Dashboard from './view/Dashboard/Dashobard';
import MyEvents from './view/MyEvents/MyEvents';
import CreateEvent from './view/MyEvents/CreateEvent';
import EditEvent from './view/MyEvents/EditEvent';
import TicketsScreen from './view/Tickets/TicketsScreen';
import VerificationScreen from './view/Verification/VerificationScreen';
import VerificationEventScreen from './view/Verification/VerificationEventScreen';

const { localStorage } = window;

let apiUrl = 'https://api.eventyapp.io/graphql'

export const appUri = apiUrl
export const urlApi = 'https://api.eventyapp.io'

const link = new CreateUploadLink({
  uri: apiUrl,
  headers: {
    Authorization: localStorage.getItem('auth_token')
      ? `Bearer ${localStorage.getItem('auth_token')}`
      : ''
  }
});



const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});


const App = () => {
  //Saving user data to the local storage
  const userData = useSelector(state => state.auth.user);

  return (
    <ApolloProvider client={client} fetchPolicy="network-only">
      <Router>
        <Notification />
        <Route exact path="/login" component={Auth} />
        <Route exact path="/register" component={Register} />
        <PrivateRoutes>
          {userData ? (
            <>
              <div className="container-scroller">
                  <TopBar />
                  <div className="container-fluid page-body-wrapper">
                    <SideBar  />
                    <div className="main-panel">
                      <ErrorBoundary>
                        <Switch>
                          <Route
                            exact
                            path={routes.dashboard.index}
                            render={routeProps => (
                              <Dashboard admin={+userData.id === 1} />
                            )}
                          />
                          <Route
                            exact
                            path={routes.tickets.index}
                            render={routeProps => (
                              <TicketsScreen admin={+userData.id === 1} />
                            )}
                          />
                          <Route
                            exact
                            path={routes.verification.index}
                            render={routeProps => (
                              <VerificationScreen admin={+userData.id === 1} />
                            )}
                          />
                          <Route
                            exact
                            path={routes.verification.show}
                            render={routeProps => (
                              <VerificationEventScreen admin={+userData.id === 1} />
                            )}
                          />
                          <Route
                            exact
                            path={routes.myEvents.index}
                            render={routeProps => (
                              <MyEvents admin={+userData.id === 1} />
                            )}
                          />
                          <Route
                            exact
                            path={routes.myEvents.create}
                            render={routeProps => (
                              <CreateEvent admin={+userData.id === 1} />
                            )}
                          />
                          <Route
                            exact
                            path={routes.myEvents.edit}
                            render={routeProps => (
                              <EditEvent admin={+userData.id === 1} />
                            )}
                          />
                          <Route exact path="/">
                            <Redirect to={routes.dashboard.index} />
                          </Route>
                          <Fallback is404={true} />
                        </Switch>
                      </ErrorBoundary>
                    <Footer />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="spinner absolute" />
          )}
        </PrivateRoutes>
      </Router>
    </ApolloProvider>
  );
};
export default App;
