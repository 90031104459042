import React , { useEffect } from 'react';

/** Redux */
import { useSelector , useDispatch  } from 'react-redux';

/** Tostify alert */ 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    autoClose: 3000,
    draggable: true,
});


const Notification = () => {

    const noti = useSelector( state => state.notification );
    const dispatch = useDispatch();

    useEffect(()=>{
        
        switch(noti.type){

            case 'success' : 

                toast.success( noti.message , {
                    position: toast.POSITION.TOP_RIGHT
                });

                break;

            case 'error' : 

                toast.error( noti.message , {
                    position: toast.POSITION.TOP_RIGHT
                });

                break;

            default : 
                return;
        }
       

     
        dispatch({
            type: 'CLEAN'
        })
     

    } , [ noti.message  , noti.type , dispatch])


    return (
        <input type="hidden" />
    );
}

export default Notification;