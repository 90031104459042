export default {
  dashboard: {
    index: '/dashboard'
  },
  tickets: {
    index: '/tickets'
  },
  verification: {
    index: '/verifications',
    show: '/verification/:id'
  },
  myEvents: {
    index: '/my-events',
    create: '/event/create',
    edit: '/event/edit/:id'
  }
};
