import gql from 'graphql-tag';

/** ---------- AUTH ------------ */
export const login = gql`
  mutation login($email: String!, $password: String!, $is_creator: Int) {
    login(email: $email, password: $password, is_creator: $is_creator) {
      id
      api_token
      email
      username
      image
      phone
    }
  }
`;

/** ---------- LOGOUT ------------ */
export const logout = gql`
  mutation logout($apiToken: String!) {
    logout(apiToken: $apiToken)
  }
`;

/** ---------- USER ------------ */
export const createUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      api_token
      email
      username
      image
      phone
      error
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      error
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      error
    }
  }
`;

export const updateUserEmail = gql`
  mutation updateUserEmail($id: Int, $email: String!) {
    updateUserEmail(id: $id, email: $email) {
      id
      error
    }
  }
`;

export const updateUserPassword = gql`
  mutation updateUserPassword($id: Int, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      id
    }
  }
`;

// Events

export const createEvent = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      error
    }
  }
`;

export const updateEvent = gql`
  mutation updateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      error
    }
  }
`;

export const updateVerificationEventUser = gql`
  mutation updateVerificationEventUser($input: UpdateVerificationEventUserInput!) {
    updateVerificationEventUser(input: $input) {
      id
      
    }
  }
`;