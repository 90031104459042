import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { getMyEvents } from '../../services/api/queryService';
import { useQuery } from 'react-apollo';
import axios from "axios";

/** Components */

const MyEvents = ({ admin }) => {
  const history = useHistory();
  const location = useLocation();

  const [events, setEvents] = useState([])

  const { loading, data, error, refetch } = useQuery(getMyEvents);

  useEffect(() => {


    if(data){
      setEvents([...data.getMyEvents])
    }


    refetch()
  }, [loading, data, refetch])

  const tabClickHandler = tabId => {
    history.push(`/products?tab=${tabId}`);
  };

  const numberFormat = num => {
    if(String(num).length == 1){
      return '0' + String(num)
    }

    return num
  }

  return (
    <>
      <div className="content-wrapper">
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <Link to="/event/create" className="btn btn-success btn-icon-text btn-sm">
                <span className="mdi  mdi-plus-box"></span> დამატება
              </Link>
            </div>
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">ჩემი ივენთები</h4>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>დასახელება</th>
                          <th>კატეგორია</th>
                          <th>სურათი</th>
                          <th>თარიღი</th>
                          <th>სტატუსი</th>
                          <th>ნახვების რაოდენობა</th>
                          <th>ნოტიფიკაციას ელოდება</th>
                          <th className='text-center'>ქმედება</th>
                        </tr>
                      </thead>
                      <tbody>
                        {events && events.map((x, i) => (
                          <tr>
                            <td>{i+1}</td>
                            <td>{x.title}</td>
                            <td>{x.category.title}</td>
                            <td>
                              <img className='img-responsive' src={x.image} alt="" />
                            </td>
                            <td>{ new Date(x.date).getDate() }/{  numberFormat(new Date(x.date).getMonth()+1) }/{ new Date(x.date).getFullYear() }</td>
                            <td>
                              { x.visible == 1 &&  <div class="badge badge-success badge-pill">აქტიური</div> }
                              { x.visible == 0 &&  <div class="badge badge-danger badge-pill">დასრულებული</div> }
                            </td>
                            <td>{ parseInt(x.views * (admin ? 1 : 1.25)) }</td>
                            <td>{ parseInt(x.waitingNotificationCount * (admin ? 1 : 1.25)) }</td>
                            <td className='text-center'> 
                              <Link to={`/event/edit/${x.id}`} className="btn btn-primary btn-icon-text btn-sm">
                                <span className="mdi mdi-pencil-box-outline"></span> რედაქტირება
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default MyEvents;
