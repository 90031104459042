import gql from 'graphql-tag';

/** ---------- PROFILE ------------ */

export const me = gql`
  query me {
    me {
      id
      email
      username
      phone
      image
    }
  }
`;

/** ---------- EVENTS ------------ */

export const getMyEvents = gql`
  query getMyEvents {
    getMyEvents{
      id
      title
      image
      created_at
      views
      visible
      date
      waitingNotificationCount
      category{
        id
        title
        icon 
        slug
      }
      subcategory{
        id
        title
        icon 
        slug
      }
    }
  }
`;

export const getMyEvent = gql`
  query getMyEvent($id: ID) {
    getMyEvent(id: $id){
      id
      title
      image
      description
      gallery
      date
      time
      address
      lat
      lng
      start_selling_date
      discount_till_date
      video
      link
      discount
      is_free
      is_online
      category{
        id
        title
        icon 
        slug
      }
      subcategory{
        id
        title
        icon 
        slug
      }
    }
  }
`;




export const categories = gql`
  query categories {
    categories{
      id
      title
      icon 
      slug
      userCount
    }
  }
`;


export const subcategories = gql`
  query subcategories {
    subcategories{
      id
      title
      icon 
      slug
      category_id
    }
  }
`;



export const verificationEventList = gql`
  query verificationEventList {
    verificationEventList{
      id
      title
      image
      userVerificationRequests{
        id
        fullname
        social_link
        dob
        status
      }
    }
  }
`;


export const verificationEvent = gql`
  query verificationEvent($id: ID) {
    verificationEvent(id: $id){
      id
      title
      image
      userVerificationRequests{
        id
        fullname
        social_link
        dob
        status
      }
    }
  }
`;
