import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoutes = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return isAuthenticated || localStorage.getItem('auth_token') ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: '/login'
      }}
    />
  );
};
