/** ---------- FETCH QUERY STRING ------------ */

export const queryStringHandler = (str = '') => {
  let perPage = 10; // how much row must be on each page
  let currentPage = 1; // current Page number
  let keyword = ''; // if search activated getting keyword
  let filter = ''; // if  module got extra filter feature
  let dateFrom = ''; // date range from
  let dateTo = ''; // date range to

  if (str) {
    // str = decodeURI(str);

    const queryString = str.slice(1).split('&');

    queryString.forEach(item => {
      let key = item.split('=')[0];
      let val = item.split('=')[1];

      if (key === 'tab') {
        return;
      }

      if (key === 'page') {
        currentPage = val;
      } else if (key === 'count') {
        perPage = val < 10 ? 10 : val;
      } else if (key === 'keyword') {
        keyword = val ? val : '';
      } else if (key === 'Datefrom') {
        dateFrom = val;
      } else if (key === 'DateTo') {
        dateTo = val;
      } else {
        filter += `${key}=${val}&`;
      }
    });
  }

  filter = filter.slice(0, -1);

  return {
    first: parseInt(perPage),
    page: parseInt(currentPage),
    keyword: keyword,
    filter: filter,
    dateFrom: dateFrom,
    dateTo: dateTo
  };
};

/** ------------ Generate Query String ----------------*/

export const generateQueryStr = (
  advancedFilter = {},
  perPage = 10,
  searchKey = '',
  fromDate = '',
  toDate = ''
) => {
  let filterQueryStr = `?count=${perPage}&page=1`;

  if (searchKey) {
    filterQueryStr += `&keyword=${searchKey}`;
  }

  if (fromDate) {
    filterQueryStr += `&Datefrom=${fromDate}`;
  }

  if (toDate) {
    filterQueryStr += `&DateTo=${toDate}`;
  }

  Object.keys(advancedFilter).forEach(key => {
    if (advancedFilter[key].value.length > 0) {
      advancedFilter[key].value.forEach(item => {
        filterQueryStr += `&${key}[]=${item.value}`;
      });
    }
  });

  return filterQueryStr;
};

/** ----------- Clean Fields OBject Values ----------------- */

export const resetFilterObject = fields => {
  Object.keys(fields).forEach(name => {
    fields[name].value = [];
  });

  return fields;
};

/** ------------ Fill filters Object with selected values from query String ---------------- */

export const queryStrToObject = (queryStr = '', filterFields) => {
  if (queryStr.length > 0) {
    const queryStrArray = queryStr.split('&');

    queryStrArray.forEach(item => {
      let key = item.split('=')[0].slice(0, -2);
      let val = item.split('=')[1];

      if (filterFields[key]) {
        const value = decodeURI(val);
        let label = value;
        if (key.includes('id')) {
          const filterField = filterFields[key].list.find(item => String(item.value) === val);
          if (filterField) {
            label = filterField.label;
          }
        }
        filterFields[key].value.push({ label, value });
      }
    });
  }

  return filterFields;
};

/** ---------- UpperCase First Character of string ------------ */

export const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** ---------- PREPARE RESPONSE FOR CHILD COMPONENT ------------ */

export const fetchData = (req, res) => {
  if (!res) {
    return {
      data: [],
      pagerData: []
    };
  }

  const { data, paginatorInfo } = res[req];

  return {
    data: data,
    pagerData: paginatorInfo
  };
};

/** ---------- PREPARE DATA STRUCTURE FOR MUTATION ------------ */

export const formatData = fields => {
  let reqData = {};
  let jsonData = {};

  Object.keys(fields).forEach(item => {
    let val = '';

    if (fields[item].inputConf.groupName) {
      jsonData[item] = fields[item].value;
    } else if (fields[item].inputType === 'select') {
      let arr = [];

      fields[item].value.forEach(element => {
        arr.push(element.value);
      });

      val = arr;
    } else if (fields[item].inputType === 'selectSingleTarget') {
      val = fields[item].value.length > 0 ? fields[item].value[0].value : '';
    } else {
      val = fields[item].value ? fields[item].value : '';
    }

    if (val || typeof val === 'number') {
      reqData[item] = val;
    }

    // if(val.length > 0 || val){

    //   reqData[item] = val;

    // }else if(item === 'status'){

    //   reqData[item] = val;
    // }
  });

  if (Object.keys(jsonData).length > 0) {
    reqData['data'] = JSON.stringify([jsonData]);
  }

  return reqData;
};

/** ---------- UPDATE FIELDS OBJECT FROM RESPONSE DATA ------------ */

export const fillFieldsData = (fields, res) => {

  const types = [
    { label: 'Pharma', value: 1 },
    { label: 'Agency', value: 2 }
  ]

  Object.keys(fields).forEach(item => {
    if (fields[item].inputType === 'select') {
      /** initial empty array */
      let ar = [];

      /** push values of each item / fill empty array  */
      res[item].forEach(element => {
        ar.push({
          value: element.id,
          label: element.name ? element.name : element.title
        });
      });
      fields[item].value = ar;
    } else if (fields[item].inputType === 'selectSingleTarget') {
      if (typeof res[item] === 'string') {
        fields[item].value = [
          {
            value: res[item],
            label: res[item]
          }
        ];
      } else if(item == 'type'){
        fields[item].value = [
          {
            value: res[item],
            label: types.filter(x => x.value == res[item])[0]?.label
          }
        ];
      } else {
        fields[item].value = [
          {
            value: res[item],
            label: res[item] ? 'Active' : 'Archived'
          }
        ];
      }
    } else {
      fields[item].value = res[item];
    }
  });

  return fields;
};

/** ---------- UPDATE OPTIONS VALUES FOR INPUT TYPE : SELECT . DATA FROM RESPONSE ------------ */

export const fillSelectInputValues = (fields, inputName, res) => {
  const inputArray = [];

  res.forEach(item => {
    let title = '';

    if (item.firstName) {
      title = item.firstName + ' ' + item.lastName;
    }

    if (item.name) {
      title = item.name;
    }

    if (item.company) {
      title = item.company;
    }

    if (item.title) {
      title = item.title;
    }

    inputArray.push({
      value: item.id,
      label: title
    });
  });

  fields[inputName].list = inputArray;

  return fields;
};

export const convertTitleToOptions = data =>
  data.map(item => ({ label: item, value: item }));

export const convertDataAttributesToOptions = data =>
  data.map(item => ({ label: item.attribute, value: item.id }));

export const convertDataToOptions = data =>
  data.map(item => ({ label: item.title, value: item.id }));
  
export const createDefaultOption = field => {
  if (field) {
    return { label: field.title, value: field.id };
  }
};

export const convertDataSlugsToOptions = data =>
  data.map(item => ({ label: item.name, value: item.slug }));
  
export const createDefaultSlugOption = field => {
  if (field) {
    return { label: field.name, value: field.slug };
  }
};

export function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);

  return array;
}