import React, { useRef, useEffect, useState } from "react";

/** Redux */
import { useDispatch } from 'react-redux';

/** Apollo */
import { useMutation } from '@apollo/react-hooks';

/** Services */
import {
    login as AUTH
} from '../../services/api/mutationService';
import { Link } from "react-router-dom";

const Auth = () => {

    /** initial value of inputs */
    const [formData , setFormData] = useState(null)    

    const dispatch = useDispatch();

    const [login, { loading, data, error }] = useMutation(AUTH);

    
    useEffect(() => {

        if(loading){

            console.log('loading...');

        } else if(data){

            if(data.login){
                localStorage.setItem('auth_token',data.login.api_token);
            
                setTimeout(()=>{
                    if(+data.login.id == 1){
                        window.location.href = '/';
                    }else{
                        window.location.href = '/my-events';
                    }
                }, 100)
            }else{
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        type: 'error',
                        message: 'Invalid Credentials'
                    }
                })
            }
            

        } else if(error){
            dispatch({
                type: 'UPDATE',
                payload: {
                    type: 'error',
                    message: 'Invalid Credentials'
                }
            })
        } else {
            const token = localStorage.getItem('auth_token');

            if(token){
                window.location.href = '/';
            }
        }

    } , [ loading , data , dispatch , error ])


    /** Login Handler */
    const submitHandler = e => {

        e.preventDefault();

        login({
            variables: {
                email: formData.email,
                password: formData.password,
                device_token: null,
                is_creator: 1
            }
        })
    }

    const changeFieldHandler = event => {
    
        setFormData({
          ...formData,
          [event.target.id]: event.target.value
        });
    };


    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                        <div className="auth-form-transparent text-left p-3">
                        <div className="brand-logo">
                            <img src="https://firebasestorage.googleapis.com/v0/b/eventyapp-d68d9.appspot.com/o/storage%2Fpush-icon.png?alt=media" alt="logo" />
                        </div>
                        <h4>ავტორიზაცია</h4>
                        <form className="pt-3" onSubmit={submitHandler}>
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail">ელ-ფოსტა</label>
                            <div className="input-group">
                                <div className="input-group-prepend bg-transparent">
                                    <span className="input-group-text bg-transparent border-right-0">
                                        <i className="mdi mdi-email-outline text-primary"></i>
                                    </span>
                                </div>
                                <input type="email" className="form-control form-control-lg border-left-0" id="email" placeholder="ელ-ფოსტა" onChange={changeFieldHandler} required={true} />
                            </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword">პაროლი</label>
                                <div className="input-group">
                                    <div className="input-group-prepend bg-transparent">
                                        <span className="input-group-text bg-transparent border-right-0">
                                            <i className="mdi mdi-lock-outline text-primary"></i>
                                        </span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg border-left-0" id="password" placeholder="პაროლი" onChange={changeFieldHandler} required={true} />                        
                                </div>
                            </div>
                            {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                <a href="#" className="auth-link text-black">დაგავიწყდა პაროლი?</a>
                            </div> */}
                            <div className="my-3">
                                <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">ავტორიზაცია</button>
                            </div>
                            {/* <div className="text-center mt-4 font-weight-light">
                                არ გაქვთ ექაუნთი? <Link to={'/register'} className="text-primary">დარეგისტრირდი</Link>
                            </div> */}
                        </form>
                        </div>
                    </div>
                    <div className="col-lg-6 login-half-bg pb-3 d-flex flex-row">
                        <p className="text-white font-weight-medium text-center flex-grow align-self-end">&copy; 2023 ყველა უფლება დაცულია.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Auth;
