import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { urlApi } from '../../App';

/** Components */

const Dashboard = ({ admin }) => {
  const history = useHistory();
  
  useEffect(() => {
    if(!admin){
      return window.location.href = '/my-events';
    }
  })

  const location = useLocation();
  const [userCount, setUserCount] = useState(0)
  const [eventCount, setEventCount] = useState(0)
  const [notificationCount, setNotificationsCount] = useState(0)
  const [totalViews, setTotalViews] = useState(0)
  const [event, setEvent] = useState(0)
  const [iosUsers, setIosUsers] = useState(0)
  const [androidUsers, setAndroidUsers] = useState(0)
  const [lastLoggedUsers, setLastLoggedUsers] = useState([])
  const [registeredUsers, setRegisteredUsers] = useState([])
  const [userEventViews, setUserEventViews] = useState([])
  const [userEventViewsTotal, setUserEventViewsTotal] = useState(0)
  const [usersTotal, setusersTotal] = useState(0)
  

  const config = {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Authorization": "Bearer " + localStorage.getItem('auth_token')
    },
};

useEffect(() => {
	Axios.post(`${urlApi}/api/get-statistics`, null, config).then(data => {
		setUserCount(data.data.usersCount)
		setEventCount(data.data.eventsCount)
		setNotificationsCount(data.data.notificationCount)
		setTotalViews(data.data.totalViews)
		setLastLoggedUsers([...data.data.lastLoggedUsers])
		setIosUsers(data.data.iosUsers)
		setAndroidUsers(data.data.androidUsers)
		setRegisteredUsers(data.data.users)
    setUserEventViews(data.data.userEventViews)
    setUserEventViewsTotal(data.data.userEventViewsTotal)
    setusersTotal(data.data.usersTotal)
	})

}, [])


const getDiffInPercentage = index => {
  const keys = Object.keys(registeredUsers)

  if(keys.length-1 == index) return <div className="badge badge-warning badge-fw">0%</div>
  
  const newNumber = registeredUsers[keys[index]].reduce((total, a) => total + a, 0)
  const prevNumber = registeredUsers[keys[index+1]].reduce((total, a) => total + a, 0)

  if(newNumber === prevNumber ) return <div className="badge badge-warning badge-fw">0%</div>

  if(newNumber > prevNumber){
    const d = newNumber - prevNumber

    const p = Math.round(d / prevNumber * 100)

    return <div className="badge badge-success badge-fw">{p}%</div>
  }else{
    const d = prevNumber - newNumber

    const p = Math.round(d / prevNumber * 100)

    return <div className="badge badge-danger badge-fw">-{p}%</div>
  }
}

  if(!admin){
    return <></>
  }

  return (
    <>
      <div className="content-wrapper">
          <div className="row">
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-primary text-white text-center card-shadow-primary">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total Users</h6>
                  <h2 className="mb-0">{ userCount }</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-danger text-white text-center card-shadow-danger">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total Events</h6>
                  <h2 className="mb-0">{eventCount}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-warning text-white text-center card-shadow-warning">
                <div className="card-body">
                  <h6 className="font-weight-normal">Notifications waiting</h6>
                  <h2 className="mb-0">{notificationCount}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-info text-white text-center card-shadow-info">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total Views</h6>
                  <h2 className="mb-0">{ totalViews }</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-lg-4 grid-margin stretch-card">
              <div className="card">
                <div className="card-body pb-0">
                  <h4 className="card-title">Daily Sales</h4>
                  <div className="d-flex justify-content-between justify-content-lg-start flex-wrap">
                    <div className="mr-5 mb-2">
                      <h3>
                        56789
                      </h3>
                      <h6 className="font-weight-normal mb-0">
                        Online sales
                      </h6>
                    </div>
                    <div className="mb-2">
                      <h3>
                        12345
                      </h3>
                      <h6 className="font-weight-normal mb-0">
                        Sales in store
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="card-body d-flex align-items-end p-0">
                  <div className="mt-auto w-100">
                    <div id="sales-legend" className="chartjs-legend mt-2 mb-4"></div>
                    <canvas id="chart-sales"></canvas>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h6 className="card-title">Events tracker last 24h ({ userEventViewsTotal })</h6>
                  </div>
                  <div className="activity_wrapper">
                      { userEventViews && userEventViews.map(item => (
                        <div className="list d-flex align-items-center border-bottom pb-3">
                          <div>
                            <a href={`/event/edit/${item.event_id}`} target='_blank'>
                              <img className="img-sm rounded-circle" style={{ objectFit: 'cover', width: '64px', height: '64px' }} src={item.event_image} alt="" />
                            </a>
                            <p style={{ marginTop: '10px', color: "#000", width: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{ item.category }</p>
                          </div>
                          <div className="wrapper w-100 ml-3 my-2">
                            <p style={{marginBottom: '4px'}}><strong>{item.username} - {item.email}</strong></p>
                            <p>{ item.event_title }</p>
                            <small className="text-muted">{item.created_at}</small>
                          </div>
                        </div>
                      )) }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h6 className="card-title">Activity ({usersTotal})</h6>
                  </div>
                  <div className="activity_wrapper">
                      { lastLoggedUsers && lastLoggedUsers.map(user => (
                        <div className="list d-flex align-items-center border-bottom pb-3">
                        <img className="img-sm rounded-circle" src={user.image} alt="" />
                        <div className="wrapper w-100 ml-3 my-2">
                          <p>{user.username} - {user.os} <br /> { user.email }</p>
                          <small className="text-muted">{user.last_login}</small>
                        </div>
                        </div>
                      )) }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">OS Devices</h4>
                  <div className="w-50 mx-auto">
                    <canvas id="traffic-chart" data-ios={iosUsers} data-android={androidUsers} width="100" height="100"></canvas>
                  </div>
                  <div className="text-center mt-5">
                    <h4 className="mb-2">OS Devices Graph</h4>
                  </div>
                  <div id="traffic-chart-legend" className="chartjs-legend traffic-chart-legend"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row grid-margin">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Registerd users per day</h4>
                  <div className="table-responsive mt-2">
                    <table className="table mt-3 border-top">
                      <thead>
                        <tr>
                          <th>თარიღი</th>
                          <th>სულ</th>
                          <th>IOS</th>
                          <th>Android</th>
                          <th>ზრდა</th>
                        </tr>
                      </thead>
                      <tbody>
                        {registeredUsers && Object.keys(registeredUsers).map((x, i) => (
                          <tr>
                            <td>{x}</td>
                            <td>{ registeredUsers[x].reduce((total, a) => total + a, 0) }</td>
                            <td>{ registeredUsers[x][0] }</td>
                            <td>{ registeredUsers[x][1] }</td>
                            <td>{ getDiffInPercentage(i) }</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Monthly Analytics</h4>
                  <p className="card-description">Products that are creating the most revenue and their sales throughout the year and the variation in behavior of sales.</p>
                  <div id="js-legend" className="chartjs-legend mt-4 mb-5 analytics-legend"></div>
                  <div className="demo-chart">
                    <canvas id="dashboard-monthly-analytics"></canvas>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 grid-margin grid-margin-md-0">
              <div className="card">
                <div className="card-body">
                  <div className="wrapper d-flex align-items-center justify-content-start justify-content-sm-center flex-wrap">
                    <img className="img-md rounded" src="https://via.placeholder.com/100x100" alt="" />
                    <div className="wrapper ml-4">
                      <p className="font-weight-medium">Tim Cook</p>
                      <p className="text-muted">timcook@gmail.com</p>
                      <p className="text-info mb-0 font-weight-medium">Designer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 grid-margin grid-margin-md-0">
              <div className="card">
                <div className="card-body">
                  <div className="wrapper d-flex align-items-center justify-content-start justify-content-sm-center flex-wrap">
                    <img className="img-md rounded" src="https://via.placeholder.com/100x100" alt="" />
                    <div className="wrapper ml-4">
                      <p className="font-weight-medium">Sarah Graves</p>
                      <p className="text-muted">Sarah@gmail.com</p>
                      <p className="text-info mb-0 font-weight-medium">Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="wrapper d-flex align-items-center justify-content-start justify-content-sm-center flex-wrap">
                    <img className="img-md rounded" src="https://via.placeholder.com/100x100" alt="" />
                    <div className="wrapper ml-4">
                      <p className="font-weight-medium">David Grey</p>
                      <p className="text-muted">David@gmail.com</p>
                      <p className="text-info mb-0 font-weight-medium">Support Lead</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Dashboard;
