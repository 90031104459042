import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { getMyEvents, verificationEventList } from '../../services/api/queryService';
import { useQuery } from 'react-apollo';
import axios from "axios";

/** Components */

const VerificationScreen = ({ admin }) => {
  const history = useHistory();
  const location = useLocation();

  const [events, setEvents] = useState([])

  const { loading, data, error, refetch } = useQuery(verificationEventList);

  useEffect(() => {


    if(data){
      setEvents([...data.verificationEventList])
    }


    refetch()
  }, [loading, data, refetch])

  const tabClickHandler = tabId => {
    history.push(`/products?tab=${tabId}`);
  };

  const numberFormat = num => {
    if(String(num).length == 1){
      return '0' + String(num)
    }

    return num
  }

  return (
    <>
      <div className="content-wrapper">
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">სია</h4>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>დასახელება</th>
                          <th>სურათი</th>
                          <th>ვერიფიკაციას ელოდება</th>
                          <th className='text-center'>ქმედება</th>
                        </tr>
                      </thead>
                      <tbody>
                        {events && events.map((x, i) => (
                          <tr>
                            <td>{i+1}</td>
                            <td>{x.title}</td>
                            <td>
                              <img className='img-responsive' src={x.image} alt="" />
                            </td>
                            <td>{ x.userVerificationRequests.filter(w => w.status == 0).length }</td>
                            <td className='text-center'> 
                              <Link to={`/verification/${x.id}`} className="btn btn-primary btn-icon-text btn-sm">
                                <span className="mdi mdi-pencil-box-outline"></span> სიის ნახვა
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default VerificationScreen;
