import React, { useState, memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

/** Redux */
import { useSelector } from 'react-redux';

const SideBar = ({  }) => {

  const userData = useSelector(state => state.auth.user);



  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard-outline menu-icon"></i>
              <span className="menu-title">დეშბორდი {userData.id == 1 ? '' : '- (მალე)'} </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/my-events">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title">ჩემი ივენთები</span>
            </Link>
          </li>
          {userData.id == 1 && (
            <li className="nav-item">
              <Link className="nav-link" to="/tickets">
                <i className="mdi mdi-ticket menu-icon"></i>
                <span className="menu-title">ბილეთები</span>
              </Link>
            </li>
          ) }

          <li className="nav-item">
            <Link className="nav-link" to="/verifications">
              <i className="mdi mdi-verified menu-icon"></i>
              <span className="menu-title">ვერიფიკაცია</span>
            </Link>
          </li>
          
          {/* <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-bullhorn menu-icon"></i>
              <span className="menu-title">მარკეტინგი</span>
            </Link>
          </li>
         
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-currency-usd menu-icon"></i>
              <span className="menu-title">ტრანზაქციები</span>
            </Link>
          </li> */}
        </ul>
      </nav>
  );
};

export default memo(SideBar);
