import React, { useRef, useEffect, useState } from "react";

/** Redux */
import { useDispatch } from 'react-redux';

/** Apollo */
import { useMutation } from '@apollo/react-hooks';

/** Services */
import {
    createUser
} from '../../services/api/mutationService';
import { Link } from "react-router-dom";
import { validateEmail } from "../../lib/constants/helpers";

const Register = () => {

    /** initial value of inputs */
    const [formData , setFormData] = useState({
        is_creator: 1
    })

    const [errors , setErrors] = useState(null)

    const dispatch = useDispatch();

    const [registerMutation, { loading, data, error }] = useMutation(createUser);

    
    useEffect(() => {

        if(loading){

            console.log('loading...');

        } else if(data){

            if(data.createUser){
                localStorage.setItem('auth_token',data.createUser.api_token);
            
                setTimeout(()=>{
                    window.location.href = '/dashboard';
                }, 100)
            }else{
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        type: 'error',
                        message: 'Invalid Credentials'
                    }
                })
            }
            

        } else if(error){
            dispatch({
                type: 'UPDATE',
                payload: {
                    type: 'error',
                    message: 'Invalid Credentials'
                }
            })
        } else {
            const token = localStorage.getItem('auth_token');

            if(token){

                window.location.href = '/dashboard';
            }
        }

    } , [ loading , data , dispatch , error ])


    /** Login Handler */
    const submitHandler = e => {

        e.preventDefault();

        const err = {}

        if(formData.username.length < 4){
            err.username = 'username უნდა იყოს მინ. 4 სიგრძის'
        }

        if(!validateEmail(formData.email)){
            err.username = 'username უნდა იყოს მინ. 4 სიგრძის'
        }

        if(formData.phone.length != 9){
            err.phone = 'ტელეფონი უნდა იყოს 9 ციფრისგან შემდგარი'
        }

        if(formData.password.length < 6){
            err.password = 'პაროლი უნდა იყოს მინ. 6 სიგრძის'
        }

        
        if(Object.keys(err).length == 0){
            registerMutation({
                variables: {input: formData}
            })
        }

        setErrors({...err})
        
    }

    const changeFieldHandler = event => {
    
        setFormData({
          ...formData,
          [event.target.id]: event.target.value
        });
    };


    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                        <div className="auth-form-transparent text-left p-3">
                        <h4>რეგისტრაცია</h4>
                        <form className="pt-3" onSubmit={submitHandler}>
                            <div className="form-group">
                            <label>Username</label>
                            <div className="input-group">
                                <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                    <i className="mdi mdi-account-outline text-primary"></i>
                                </span>
                                </div>
                                <input type="text" className="form-control form-control-lg border-left-0" id="username" placeholder="Username" required={true} onChange={changeFieldHandler} />
                                {
                                    errors && errors.username && (
                                        <div className="errorText">
                                            <p className="text-danger">{ errors.username }</p>
                                        </div>
                                    )
                                }
                            </div>
                            </div>
                            <div className="form-group">
                                <label>ელ-ფოსტა</label>
                                <div className="input-group">
                                    <div className="input-group-prepend bg-transparent">
                                    <span className="input-group-text bg-transparent border-right-0">
                                        <i className="mdi mdi-email-outline text-primary"></i>
                                    </span>
                                    </div>
                                    <input type="email" className="form-control form-control-lg border-left-0" id="email" placeholder="ელ-ფოსტა" required={true} onChange={changeFieldHandler}  />
                                    {
                                        errors && errors.email && (
                                            <div className="errorText">
                                                <p className="text-danger">{ errors.email }</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label>ტელეფონი</label>
                                <div className="input-group">
                                    <div className="input-group-prepend bg-transparent">
                                    <span className="input-group-text bg-transparent border-right-0">
                                        <i className="mdi mdi-phone text-primary"></i>
                                    </span>
                                    </div>
                                    <input type="number" className="form-control form-control-lg border-left-0" id="phone" placeholder="ტელეფონი" required={true} onChange={changeFieldHandler}  />
                                    {
                                        errors && errors.phone && (
                                            <div className="errorText">
                                                <p className="text-danger">{ errors.phone }</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <label>პაროლი</label>
                                <div className="input-group">
                                    <div className="input-group-prepend bg-transparent">
                                        <span className="input-group-text bg-transparent border-right-0">
                                            <i className="mdi mdi-lock-outline text-primary"></i>
                                        </span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg border-left-0" id="password" placeholder="Password" required={true} onChange={changeFieldHandler} />
                                    {
                                        errors && errors.password && (
                                            <div className="errorText">
                                                <p className="text-danger">{ errors.password }</p>
                                            </div>
                                        )
                                    }                        
                                </div>
                            </div>
                            <div className="mb-4 form-group">
                                <div className="form-check">
                                    <label className="form-check-label text-muted">
                                        <input type="checkbox" className="form-check-input" required={true} />
                                        ვეთანხმები წესებს და პირობებს
                                        <i className="input-helper"></i>   
                                    </label>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button type="submit"  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">რეგისტრაცია</button>
                            </div>
                            <div className="text-center mt-4 font-weight-light">
                                უკვე გაქვთ ექაუნთი? <Link to={'/login'} className="text-primary">ავტორიზაცია</Link>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div className="col-lg-6 register-half-bg d-flex flex-row">
                        <p className="text-white font-weight-medium text-center flex-grow align-self-end">&copy; 2023 ყველა უფლება დაცულია.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Register;
