import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { getMyEvents, verificationEvent, verificationEventList } from '../../services/api/queryService';
import { useMutation, useQuery } from 'react-apollo';
import axios from "axios";
import { updateVerificationEventUser } from '../../services/api/mutationService';

/** Components */

const VerificationEventScreen = ({ admin }) => {
  const history = useHistory();
  const location = useLocation();

  const [event, setEvent] = useState([])

  const [updateVerificationEventUserMutation, {}] = useMutation(updateVerificationEventUser);

  const { loading, data, error, refetch } = useQuery(verificationEvent, {
    variables: {id: location.pathname.split('/')[2]}
  });

  useEffect(() => {


    if(data){
      setEvent({...data.verificationEvent})
    }


    refetch()
  }, [loading, data, refetch])

  const tabClickHandler = tabId => {
    history.push(`/products?tab=${tabId}`);
  };

  const handelStatusChange = (id, status) => {
    updateVerificationEventUserMutation({variables: {input: {id, status }}}).then(() => {
      refetch()
    })
  }


  return (
    <>
      <div className="content-wrapper">
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{event.title}</h4>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>სახელი, გვარი</th>
                          <th>დაბადების თარიღი</th>
                          <th>სოც. ქსელი</th>
                          <th>სტატუსი</th>
                          <th className='text-center'>ქმედება</th>
                        </tr>
                      </thead>
                      <tbody>
                        {event && event.userVerificationRequests && event.userVerificationRequests.map((x, i) => (
                          <tr>
                            <td>{i+1}</td>
                            <td>{x.fullname}</td>
                            <td>{x.dob}</td>
                            <td><a href={x.social_link} target='_blank'>{x.social_link}</a></td>
                            <td>
                              { x.status == 1 && <img src="/assets/images/check.png" style={{ width: '40px', height: '40px', objectFit: 'cover' }} /> }
                              { x.status == 2 && <img src="/assets/images/close.png" style={{ width: '40px', height: '40px', objectFit: 'cover' }} /> }
                              { x.status == 0 && <img src="/assets/images/pending.png" style={{ width: '40px', height: '40px', objectFit: 'cover' }} /> }
                            </td>
                            <td className='text-center'> 
                              { x.status == 0 && (
                                <>
                                  <button className='btn btn-success btn-sm mr-3' onClick={() => handelStatusChange(x.id, 1)}><span className="mdi  mdi-check text-white"></span></button>
                                  <button className='btn btn-danger btn-sm' onClick={() => handelStatusChange(x.id, 2)}><span className="mdi  mdi-close text-white"></span></button>
                                </>
                              ) }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default VerificationEventScreen;
