import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Calendar from 'react-calendar';

import Autocomplete from "react-google-autocomplete";
/** Components */

import 'react-calendar/dist/Calendar.css';
import { createEvent } from '../../services/api/mutationService';
import { useMutation, useQuery } from 'react-apollo';
import Axios from 'axios';
import { appUri, urlApi } from '../../App';
import { convertDataToOptions, createDefaultOption } from '../../services/util/dataHandlerService';
import { categories, subcategories } from '../../services/api/queryService';

const CreateEvent = ({ admin }) => {

    const todaysDate = () => {
        let d = new Date().getDate()
        let m = new Date().getMonth() + 1
        let y = new Date().getFullYear()

        if(d < 10){
            d = `0${d}`
        }

        if(m < 10){
            m = `0${m}`
        }

        return `${d}/${m}/${y}`
    }

    const [formData, setFormData] = useState({ 
        sellStartDate: todaysDate(),
        is_free: 0,
        is_online: 0,
        discountTillDate: null,
        prices: [
            {
                isChecked: false
            },
            {
                isChecked: false
            },
            {
                isChecked: false
            },
        ],
        vip: {
            isVip: false,
            price: 0,
            amount: 0
        },
        promoCode: {
            enabled: false,
            errors: []
        }
    })

    const [categoriesList, setCategoriesList] = useState([])
    const [subcategoriesList, setSubcategoriesList] = useState([])
    const [address, setAddress] = useState({})
    const [page, setPage] = useState(1)

    const dispatch = useDispatch()
    const history = useHistory();

    const { data: categoriesData, loading: categoriesLoading } = useQuery(categories);
    const { data: subcategoriesData, loading: subcategoriesLoading } = useQuery(subcategories);
    
    useEffect(() => {
        if(categoriesData){
            setCategoriesList(categoriesData.categories)
        }

        if(subcategoriesData){
            setSubcategoriesList(subcategoriesData.subcategories)
        }

    }, [categoriesLoading, subcategoriesLoading])

    const [createEventMutation, { loading, data, error }] = useMutation(createEvent);

    const [showCalendar, setShowCalendar] = useState(false)
    const [showDiscountCalendar, setShowDiscountCalendar] = useState(false)
    const [dateValue, setDateValue] = useState(false)
    const [sellDateValue, setSellDateValue] = useState(new Date())
    const [discountTillDate, setDiscountTillDate] = useState(new Date())
    const [gallery, setGallery] = useState([])
    const [galleryFiles, setGalleryFiles] = useState([])
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)

    const imageRef = useRef(null)
    const galleryRef = useRef(null)
    const videoRef = useRef(null)

    const changeFieldHandler = event => {
        const fd = {...formData}
        if(event.target.id == 'is_free'){
            fd.is_free = event.target.value == 'false' ? 0 : 1
        }else if(event.target.id == 'is_online'){
            fd.is_online = event.target.value == 'false' ? 0 : 1
        }else{
            fd[event.target.id] = event.target.value
        }

        setFormData({...fd});
      
        setErrors({})
    };

    // const checkboxHandler = event => {
    //     setFormData({
    //         ...formData,
    //         [event.target.id]: !event.target.value
    //     });
    // }

    const changeCheckboxHandler = (event, index) => {

        if(event.target.id == 'promocode'){
            const code = {...formData.promoCode}
            code.enabled = !code.enabled

            setFormData({
                ...formData,
                ['promoCode']: {...code}
            });
        }else{
            const prices = [...formData.prices]

            prices[index].isChecked = !prices[index].isChecked

            setFormData({
                ...formData,
                ['prices']: [...prices]
            });
        }
    }

    const setVipTicketHandler = event => {
        const vip = {...formData.vip}
        vip.isVip = !vip.isVip

        setFormData({
            ...formData,
            ['vip']: {...vip}
        });
    }

    const changeTicketDataHandler = (event, index) => {

        const prices = [...formData.prices]

        prices[index][event.target.id] = event.target.value

        setFormData({
            ...formData,
            ['prices']: [...prices]
        });

    }

    const changeVipTicketHandler = event => {
        const vip = {...formData.vip}

        vip[event.target.id] = event.target.value

        setFormData({
            ...formData,
            ['vip']: {...vip}
        });
    }

    const changePromoCodeDataHandler = (event) => {

        const code = {...formData.promoCode}

        code[event.target.id] = event.target.value

        setFormData({
            ...formData,
            ['promoCode']: {...code}
        });

    }

    

    const changeDescription = val => {
        const fd = {...formData}
        fd['description'] = val

        setFormData({...fd});
        setErrors({})
    }

    const changeDate = val => {
        let d = new Date(val).getDate()
        let m = new Date(val).getMonth() + 1
        let y = new Date(val).getFullYear()

        if(d < 10){
            d = `0${d}`
        }

        if(m < 10){
            m = `0${m}`
        }

        setFormData({
            ...formData,
            ['date']: `${d}/${m}/${y}`
        });

        setDateValue(val)
        setShowCalendar(false)
    }

    const changeSellDate = val => {
        let d = new Date(val).getDate()
        let m = new Date(val).getMonth() + 1
        let y = new Date(val).getFullYear()

        if(d < 10){
            d = `0${d}`
        }

        if(m < 10){
            m = `0${m}`
        }

        setFormData({
            ...formData,
            ['sellStartDate']: `${d}/${m}/${y}`
        });

        setSellDateValue(val)
        setShowCalendar(false)
    }

    const changeDiscountDate = val => {
        let d = new Date(val).getDate()
        let m = new Date(val).getMonth() + 1
        let y = new Date(val).getFullYear()

        if(d < 10){
            d = `0${d}`
        }

        if(m < 10){
            m = `0${m}`
        }

        setFormData({
            ...formData,
            ['discountTillDate']: `${d}/${m}/${y}`
        });

        setDiscountTillDate(val)
        setShowDiscountCalendar(false)
    }

    
    
    const videoHandle = event => {

        const fd = {...formData}

        Object.keys(event.target.files).map((file, i) => {
            fd.video = URL.createObjectURL(event.target.files[i])
            fd.hasVideo = true
            fd.videoFile = event.target.files[i]
        })

        setFormData({...fd});
    
        setErrors({})
    }

    const imageHandle = event => {

        const fd = {...formData}

        Object.keys(event.target.files).map((file, i) => {
            fd.image = URL.createObjectURL(event.target.files[i])
            fd.hasImage = true
            fd.file = event.target.files[i]
        })

        setFormData({...fd});
    
        setErrors({})
    }

    const multipleImageHandle = event => {
        console.log(Object.keys(event.target.files))
        const images = []
        const galleryArray = []
        Object.keys(event.target.files).map((file, i) => {
            images.push(URL.createObjectURL(event.target.files[i]))
            galleryArray.push(event.target.files[i])
        })
        
        setGalleryFiles([...galleryArray])

        setGallery([...images])
        // URL.createObjectURL
    }

    const removeGalleryImage = index => {
        const g = [...gallery]
        g.splice(index, 1)
        const galleryArray = [...galleryFiles]
        galleryArray.splice(index, 1)


        setGalleryFiles([...galleryArray])
        setGallery([...g])
    }

    const changeAddress = place => {

        const fd = {...address}
        fd['lat'] = place.geometry.location.lat()
        fd['lng'] = place.geometry.location.lng()
        fd['address']  = place.formatted_address

        setErrors({})
        setAddress({...fd});
    }

    const changeSelectHandler = (option, data) => {
        setFormData({
          ...formData,
          [data.name]: option && +option.value
        });
    };


    const handleNextStep = () => {
        let pageCount = page

        if(pageCount == 4){
            pageCount = 4
        }else{
            pageCount++
        }

        if(pageCount == 2){
            validateFormDetails().then(err => {
                if(Object.keys(err).length == 0){
                    setPage(pageCount)
                }
            })
        }

        if(pageCount == 3){
            validateTicketDetails().then(hasError => {
                if(!hasError){
                    setPage(pageCount)
                }
            })
        }

        
        if(pageCount == 4){
            validatePromoCodeDetails().then(hasError => {
                if(!hasError){
                    setPage(pageCount)
                }
            })
        }
        



    }

    const handlePrevStep = () => {
        let pageCount = page
        if(pageCount == 1){
            pageCount = 1
        }else{
            pageCount--
        }


        console.log(errors)

        setPage(pageCount)
    }

    const validateTicketDetails = async () => {
        const fd = {...formData}
        let hasError = false

        fd.prices.map(x => {
            x.errors = []
            if(x.isChecked){

                if(x.price == 0 || !x.price){
                    x.errors.price = 'ფასის მითითება აუცილებელია'
                    hasError = true
                }

                if(x.amount == 0 || !x.amount){
                    x.errors.amount = 'რაოდენობის მითითება აუცილებელია'
                    hasError = true
                }

                if(x.days == 0 || !x.days){
                    x.errors.days = 'დღეების მითითება აუცილებელია'
                    hasError = true
                }

            }
        })

        const errs = {}

        if(fd.discount && (fd.discount < 0 || fd.discount > 100)){
            hasError = true
            errs.discount = 'ფასდაკლების % უნდა იყოს 0 და 100 შორის'
        }

        if(fd.discount && fd.discount > 0 && fd.discount < 100 && !fd.discountTillDate){
            hasError = true
            errs.discountTillDate = 'ფასდაკლების შემთხვევაში მიუთითეთ დასრულების თარიღი'
        }

        setErrors(errs)
        setFormData({...fd})

        return hasError
    }

    const validatePromoCodeDetails = async () => {
        const fd = {...formData}
        let hasError = false

        if(fd.promoCode.enabled){

            if(fd.promoCode.discount == 0 || !fd.promoCode.discount){
                fd.promoCode.errors.discount = 'ფასდაკლების მითითება აუცილებელია'
                hasError = true
            }

            if(fd.promoCode.amount == 0 || !fd.promoCode.amount){
                fd.promoCode.errors.amount = 'რაოდენობის მითითება აუცილებელია'
                hasError = true
            }

            if(fd.promoCode.days == 0 || !fd.promoCode.days){
                fd.promoCode.errors.days = 'დღეების მითითება აუცილებელია'
                hasError = true
            }

        }

        if(!hasError){
            fd.promoCode.errors = []
        }

        setFormData({...fd})

        return hasError
    }

    const validateFormDetails = async () => {
        setLoader(true)

        const payload = {...formData, ...address}
        const errs = {}

        if(!payload.title){
            errs.title = 'სათაური აუცილებელია'
        }else if(payload.title.length < 4){
            errs.title = 'სათაური უნდა შეიცავდე მინ. 4 სიმბოლოს'
        }

        if(!payload.description){
            errs.description = 'აღწერა აუცილებელია'
        }else if(payload.description.length < 10){
            errs.description = 'აღწერა უნდა შეიცავდე მინ. 10 სიმბოლოს'
        }

        if(!payload.category_id){
            errs.category_id = 'კატეგორია აუცილებელია'
        }

        if(!payload.date){
            errs.date = 'თარიღი აუცილებელია'
        }
        
        if(!payload.time){
            errs.time = 'დრო აუცილებელია'
        }

        if(!payload.image){
            errs.image = 'მთავარი სურათი აუცილებელია'
        }

        if(!payload.address && !!(!payload.is_online)){
            errs.address = 'მისამართი აუცილებელია'
        }

        if(Object.keys(errs).length !== 0){
            setErrors({...errs})
        }

        setLoader(false)

        return errs
    }

    const submitHandler = async () => {
        const payload = {...formData, ...address}

        delete payload.hasImage
        delete payload.image
        delete payload.file
        delete payload.videoFile
        delete payload.video
        setLoader(true)


        payload.vip = JSON.stringify(payload.vip)
        payload.prices = JSON.stringify(payload.prices)
        payload.promoCode = JSON.stringify(payload.promoCode)



        const response = await createEventMutation({
            variables: { input: payload }
        })

        if(response && response.data.createEvent){
            const fData = new FormData();
            fData.append(`image`, formData.file)
            fData.append(`video`, formData.videoFile)

            galleryFiles.filter(x => x).map((x, i) => {
                fData.append(`gallery${i}`, x)
            })

            fData.append(`event_id`, response.data.createEvent.id)

            Axios.post(`${urlApi}/api/image-upload`, fData)
            .then(res => {
                setLoader(false)
                
                if(res.data.status == 200){
                    dispatch({
                        type: 'UPDATE',
                        payload: {
                          type: 'success',
                          message: 'ივენთი წარმატებით დაემატა'
                        }
                    });

                    return history.push(`/my-events`);
                }else{
                    dispatch({
                        type: 'UPDATE',
                        payload: {
                          type: 'error',
                          message: 'დამატების დროს დაფიქსირდა შეცდომა'
                        }
                    });
                }

            }).finally(() => setLoader(false))
        }else{
            setLoader(false)
        }
    }

    console.log(formData)

    return (
        <>
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <Link to="/my-events" className="btn btn-secondary btn-icon-text btn-sm">
                            <span className="mdi   mdi-arrow-left"></span> უკან დაბრუნება
                        </Link>
                    </div>
                    {admin && (
                        <div className="col-lg-12 grid-margin stretch-card px-0">
                            <div className="col-3">
                                <button onClick={() => page == 1 ? null : (page > 1 ? handlePrevStep() : handleNextStep())} className={`btn btn-block ${page == 1 ? 'btn-primary' : 'btn-secondary'}`}>დეტალები</button>
                            </div>
                            <div className="col-3">
                                <button  onClick={() => page == 2 ? null : (page > 2 ? handlePrevStep() : handleNextStep())} className={`btn btn-block ${page == 2 ? 'btn-primary' : 'btn-secondary'}`}>ბილეთები</button>
                            </div>
                            <div className="col-3">
                                <button  onClick={() => page == 3 ? null : (page > 3 ? handlePrevStep() : handleNextStep())} className={`btn btn-block ${page == 3 ? 'btn-primary' : 'btn-secondary'}`}>ფასდაკლების კოდი</button>
                            </div>
                            <div className="col-3">
                                <button  onClick={() => page == 4 ? null : (page > 4 ? handlePrevStep() : handleNextStep())} className={`btn btn-block ${page == 4 ? 'btn-primary' : 'btn-secondary'}`}>დასრულება</button>
                            </div>
                        </div>
                    )}

                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">ივენთის დამატება</h4>
                                
                                { page == 1 && (

                                    <form className="forms-sample" autoComplete='off' onSubmit={submitHandler}>
                                        <div className="form-group ">
                                            <label >მთავარი სურათი <span style={{color:'red'}}>*</span></label>
                                            <div className="col-4">

                                                { formData && formData.image && (
                                                    <img src={formData.image} alt="" style={{ maxWidth: '300px', maxHeight: '200px', margin: '5px', objectFit: 'contain' }} />
                                                )}

                                                <input type="file" onChange={imageHandle} ref={imageRef} />
                                            </div>
                                            {
                                                errors.image && (
                                                    <p className="text-danger p-1">{errors.image}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group ">
                                            <label >გალერეა</label>
                                            <div className="col-8">
                                                <input type="file" onChange={multipleImageHandle} ref={galleryRef} multiple max={6} />

                                                <div className="galleryPreview" style={{ display: 'flex', flexWrap: 'wrap', margin: '35px 0' }}>
                                                    { gallery.length > 0 && gallery.map((img, index) => (
                                                        <div className="galleryItem" style={{ position: 'relative' }}>
                                                            <img src={img} alt="" style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px', objectFit: 'contain' }} />
                                                            <i style={{ position: 'absolute', top: '-20px', right: '0', color: 'red', fontSize: '24px', cursor: 'pointer' }} onClick={() => removeGalleryImage(index)} className='mdi mdi-close-box'></i>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>

                                        {admin && (
                                            <div className="form-group ">
                                                <label >პრომო ვიდეო </label>
                                                <div className="col-6 mt-3">

                                                    { formData && formData.video && (
                                                        <video  controls style={{ maxWidth: '400px', maxHeight: '280px', margin: '5px', objectFit: 'contain', display: 'block' }} >
                                                            <source src={formData.video} type="video/mp4" />
                                                        </video>  
                                                    )}

                                                    <input type="file" onChange={videoHandle} ref={videoRef} />
                                                </div>
                                                {
                                                    errors.image && (
                                                        <p className="text-danger p-1">{errors.image}</p>
                                                    )
                                                }
                                            </div>
                                        )}
                                        
                                        { formData && categoriesList && formData.category_id && (
                                            <div className="form-group col-4 px-0">
                                                    <h4>სამიზნე აუდიტორია: <span style={{ fontWeight: 'bold' }}>{ parseInt(categoriesList.find(x => x.id == formData.category_id).userCount * (admin ? 1 : 1.25)) }</span></h4>
                                            </div>
                                        ) }

                                        <div className="form-group col-4 px-0">
                                            <label>აირჩიეთ კატეგორია</label>
                                            <Select
                                                name="category_id"
                                                placeholder="კატეგორია"
                                                options={convertDataToOptions(categoriesList)}
                                                value={createDefaultOption(
                                                    categoriesList.find(i => +i.id === formData.category_id)
                                                )}
                                                onChange={changeSelectHandler}
                                            />
                                            {
                                                errors.category_id && (
                                                    <p className="text-danger p-1">{errors.category_id}</p>
                                                )
                                            }
                                        </div>
                                        {admin && (
                                            <div className="form-group col-4 px-0">
                                                <label>აირჩიეთ ქვეკატეგორია</label>
                                                <Select
                                                    name="subcategory_id"
                                                    placeholder="ქვეკატეგორია"
                                                    options={convertDataToOptions(formData.category_id ? subcategoriesList.filter(x => +x.category_id == +formData.category_id) : [])}
                                                    value={createDefaultOption(
                                                        subcategoriesList.find(i => +i.id === formData.subcategory_id)
                                                    )}
                                                    onChange={changeSelectHandler}
                                                />
                                                {
                                                    errors.subcategory_id && (
                                                        <p className="text-danger p-1">{errors.subcategory_id}</p>
                                                    )
                                                }
                                            </div>
                                        )}
                                        <div className="form-group">
                                            <label >სათაური <span style={{color:'red'}}>*</span></label>
                                            <input  type="text" className="form-control" id="title" placeholder="სათაური" onChange={changeFieldHandler} value={formData.title} />
                                            {
                                                errors.title && (
                                                    <p className="text-danger p-1">{errors.title}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >აღწერა <span style={{color:'red'}}>*</span></label>
                                            <ReactQuill theme="snow" id='description' value={formData.description??''} onChange={(e) => changeDescription(e)} />
                                            {
                                                errors.description && (
                                                    <p className="text-danger p-1">{errors.description}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >მისამართი { !!(!formData.is_online) && <span style={{color:'red'}}>*</span> }</label>
                                            <Autocomplete
                                                apiKey={'AIzaSyAKDaCAnAohNpSQNdS2QU7qr9SXv3WKLPA'}
                                                className="form-control"
                                                onPlaceSelected={(place, ref) => {
                                                    changeAddress(place)
                                                }}
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                                id='address'
                                                language='ka'
                                                inputAutocompleteValue={address ? address.address : ''}
                                                placeholder='მაგ: 88 წერეთლის გამზ...'
                                                autoComplete='off'
                                            />
                                            {
                                                errors.address && (
                                                    <p className="text-danger p-1">{errors.address}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >თარიღი <span style={{color:'red'}}>*</span></label>
                                            <input type="text" className="form-control col-4" id="date" value={formData.date} onClick={() => setShowCalendar(true)}/>
                                            {showCalendar && <Calendar onChange={changeDate} value={dateValue} minDate={new Date()} />}
                                            {
                                                errors.date && (
                                                    <p className="text-danger p-1">{errors.date}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >დრო <span style={{color:'red'}}>*</span></label>
                                            <input className="form-control col-4" type='time' id='time' value={formData.time} onChange={changeFieldHandler} />
                                            {
                                                errors.time && (
                                                    <p className="text-danger p-1">{errors.time}</p>
                                                )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label >ლინკი</label>
                                            <input  type="text" className="form-control col-6" id="link" placeholder="ლინკი" onChange={changeFieldHandler}  />
                                        </div>

                                        <div className="form-group">
                                            <div class="form-check form-check-flat form-check-primary">
                                                <label class="cursor-pointer">
                                                    უფასო
                                                    <input type="checkbox" className='ml-3' id='is_free' onChange={changeFieldHandler} checked={!!formData.is_free}  value={!formData.is_free} />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div class="form-check form-check-flat form-check-primary">
                                                <label class="cursor-pointer">
                                                    Online
                                                    <input type="checkbox" className='ml-3' id='is_online' onChange={changeFieldHandler} checked={!!formData.is_online}  value={!formData.is_online} />
                                                </label>
                                            </div>
                                        </div>


                                    </form>

                                ) }

                                { page == 2 && (
                                    <>
                                        <div className="col-lg-12 grid-margin stretch-card">
                                            <label htmlFor="">მონიშნეთ თუ გსურთ ბილეთების გაყიდვა</label>
                                        </div>

                                        <div className="col-lg-12 grid-margin stretch-card">
                                            <div className="form-group col-4">
                                                <label >გაყიდვების დაწყება <span style={{color:'red'}}>*</span></label>
                                                <input type="text" className="form-control" id="sellStartDate" value={formData.sellStartDate} onClick={() => setShowCalendar(true)}/>
                                                {showCalendar && <Calendar onChange={changeSellDate} value={sellDateValue} minDate={new Date()} />}
                                                {
                                                    errors.sellStartDate && (
                                                        <p className="text-danger p-1">{errors.sellStartDate}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-12 grid-margin stretch-card flex-wrap'>
                                            
                                            { formData && formData.prices && formData.prices.map((x, i) => (
                                                <>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <div class="form-check form-check-flat form-check-primary">
                                                                <label class="cursor-pointer">
                                                                    <input type="checkbox" className='mr-3' onChange={(event) => changeCheckboxHandler(event, i)} checked={formData.prices[i].isChecked}  value={formData.prices[i].isChecked} />
                                                                    კალათა {i+1}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label >ფასი {formData.prices[i].isChecked && <span style={{color:'red'}}>*</span>}  </label>
                                                            <input  type="number" className="form-control" disabled={!formData.prices[i].isChecked} id="price" placeholder="ფასი" onChange={(event) => changeTicketDataHandler(event, i)} value={x.price} />
                                                            {
                                                                x.errors?.price && (
                                                                    <p className="text-danger p-1">{x.errors.price}</p>
                                                                )
                                                            }
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                            <label >მაქს. რაოდენობა {formData.prices[i].isChecked && <span style={{color:'red'}}>*</span>}</label>
                                                            <input  type="number" className="form-control" id="amount" disabled={!formData.prices[i].isChecked}  placeholder="მაგ: 250" onChange={(event) => changeTicketDataHandler(event, i)} value={x.amount} />
                                                            {
                                                                x.errors?.amount && (
                                                                    <p className="text-danger p-1">{x.errors.amount}</p>
                                                                )
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <label >მაქს. დღეების რაოდენობა {formData.prices[i].isChecked && <span style={{color:'red'}}>*</span>}</label>
                                                            <input  type="number" className="form-control" id="days" disabled={!formData.prices[i].isChecked}  placeholder="მაგ: 14" onChange={(event) => changeTicketDataHandler(event, i)} value={x.days} />
                                                            {
                                                                x.errors?.days && (
                                                                    <p className="text-danger p-1">{x.errors.days}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )) }


                                            <>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <div class="form-check form-check-flat form-check-primary">
                                                            <label class="cursor-pointer">
                                                                <input type="checkbox" className='mr-3' onChange={setVipTicketHandler} checked={formData.vip.isVip}  value={formData.vip.isVip} />
                                                                VIP ბილთი
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label >ფასი {formData.vip.isVip && <span style={{color:'red'}}>*</span>}  </label>
                                                        <input  type="number" className="form-control" disabled={!formData.vip.isVip} id="price" placeholder="ფასი" onChange={changeVipTicketHandler} value={formData.vip.price} />
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label >მაქს. რაოდენობა {formData.vip.isVip && <span style={{color:'red'}}>*</span>}</label>
                                                        <input  type="number" className="form-control" id="amount" disabled={!formData.vip.isVip}  placeholder="მაგ: 250" onChange={changeVipTicketHandler} value={formData.vip.amount} />
                                                    </div>
                                                </div>
                                            </>

                                        </div>

                                        {/* <div className="col-lg-12 grid-margin stretch-card">
                                            <div className="form-group col-12">
                                                <label >ფასდაკლების % (ამ ველის გასააქტიურებლად შეავსეთ ფასის კალათა)</label>
                                                <input  type="number" className="form-control col-4" disabled={!formData.prices.some(x => x.isChecked)}  min={0} max={100} id="discount" placeholder="ფასდაკლების %" onChange={changeFieldHandler} value={formData.discount} />
                                                {
                                                    errors.discount && (
                                                        <p className="text-danger p-1">{errors.discount}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-12 grid-margin stretch-card">

                                            <div className="form-group col-4">
                                                <label >ფასდაკლების დასრულების თარიღი</label>
                                                <input type="text" className="form-control" id="discountTillDate"  disabled={!formData.prices.some(x => x.isChecked)} value={formData.discountTillDate} onClick={() => setShowDiscountCalendar(true)}/>
                                                {showDiscountCalendar && <Calendar onChange={changeDiscountDate} value={discountTillDate} minDate={new Date()} />}
                                                {
                                                    errors.discountTillDate && (
                                                        <p className="text-danger p-1">{errors.discountTillDate}</p>
                                                    )
                                                }
                                            </div>
                                        </div> */}
                                    </>

                                ) }

                                { page == 3 && (

                                    <>
                                        <div className="col-lg-12 grid-margin stretch-card">
                                            <label htmlFor="">მონიშნეთ თუ გსურთ ფასდაკლების კოდის გამოყენება</label>
                                        </div>
                                        <div className='col-lg-12 grid-margin stretch-card'>
                                            
                                            <>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <div class="form-check form-check-flat form-check-primary">
                                                            <label class="cursor-pointer">
                                                                <input type="checkbox" className='mr-3' id='promocode' onChange={(event) => changeCheckboxHandler(event, 0)} checked={formData.promoCode.enabled} value={formData.promoCode.enabled} />
                                                                პრომო კოდი
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>ფასდაკლებული % {formData.promoCode.enabled && <span style={{color:'red'}}>*</span>}  </label>
                                                        <input  type="number" className="form-control" disabled={!formData.promoCode.enabled} id="discount" placeholder="ფასდაკლებული % " onChange={(event) => changePromoCodeDataHandler(event)} value={formData.promoCode.discount} />
                                                        {
                                                            formData.promoCode?.errors?.discount && (
                                                                <p className="text-danger p-1">{formData.promoCode.errors.discount}</p>
                                                            )
                                                        }
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label >მაქს. რაოდენობა {formData.promoCode.enabled && <span style={{color:'red'}}>*</span>}</label>
                                                        <input  type="number" className="form-control" id="amount" disabled={!formData.promoCode.enabled}  placeholder="მაგ: 250" onChange={(event) => changePromoCodeDataHandler(event)} value={formData.promoCode.amount} />
                                                        {
                                                            formData.promoCode?.errors?.amount && (
                                                                <p className="text-danger p-1">{formData.promoCode.errors.amount}</p>
                                                            )
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label >მაქს. დღეების რაოდენობა {formData.promoCode.enabled && <span style={{color:'red'}}>*</span>}</label>
                                                        <input  type="number" className="form-control" id="days" disabled={!formData.promoCode.enabled}  placeholder="მაგ: 14" onChange={(event) => changePromoCodeDataHandler(event)} value={formData.promoCode.days} />
                                                        {
                                                            formData.promoCode?.errors?.days && (
                                                                <p className="text-danger p-1">{formData.promoCode.errors.days}</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>

                                        </div>
                                    </>
                                ) }

                                { page == 4 && (
                                    <>
                                        <div className="col-lg-12 grid-margin stretch-card">
                                            <div class="form-check form-check-flat form-check-primary">
                                                <label class="cursor-pointer">
                                                    <input type="checkbox" className='mr-3' onChange={null} value={1} />
                                                    ვეთანხმები ივენთის წესებს და პირობებს
                                                </label>
                                            </div>
                                        </div>
                                    </>

                                ) }


                                {!admin ? (
                                    <>
                                        {loader ? (
                                            <div class="circle-loader"></div>
                                        ) : (
                                            <button type="submit" onClick={submitHandler} className="btn btn-success mr-2">დასრულება</button>
                                        )}
                                    </>
                                ) : ( 
                                    <div className='d-flex justify-content-end'>
                                        <>
                                            { loader && (
                                                <div class="circle-loader"></div>
                                            ) }
                                            { !loader && page != 1 && page < 4 && (
                                                <button type="submit" className="btn btn-secondary mt-4 mr-4" onClick={handlePrevStep}>წინა</button>
                                            )}

                                            { !loader && page < 4 && (
                                                <button type="submit" className="btn btn-primary mt-4" onClick={handleNextStep}>შემდეგი</button>
                                            )}

                                            { !loader && page == 4 && (
                                                <button type="submit" className="btn btn-success mt-4" onClick={submitHandler}>დასრულება</button>
                                            )}
                                        </>
                                    </div>
                                )}                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <script src='/assets/main.js'></script>
        </>
    );
};

export default CreateEvent;
