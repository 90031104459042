import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { urlApi } from '../../App';

/** Components */

const TicketsScreen = ({ admin }) => {
  const history = useHistory();
  
  useEffect(() => {
    if(!admin){
      return window.location.href = '/my-events';
    }
  })

  const location = useLocation();
  const [totalTickets, setTotalTickets] = useState(0)
  const [totalTicketsReserved, setTotalTicketsReserved] = useState(0)
  const [totalSoldTickets, setTotalSoldTickets] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)
  const [reservedList, setReservedNow] = useState([])
  const [soldTicketsList, setSoldTicketsList] = useState([])

  const config = {
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Authorization": "Bearer " + localStorage.getItem('auth_token')
    },
};

useEffect(() => {
	Axios.post(`${urlApi}/api/get-ticket-statistics`, null, config).then(data => {
		setTotalTickets(data.data.totalTickets)
		setTotalTicketsReserved(data.data.totalTicketsReserved)
		setTotalSoldTickets(data.data.totalSoldTickets)
		setTotalIncome(data.data.totalIncome)
		setReservedNow([...data.data.reservedNow])
    setSoldTicketsList([...data.data.soldTickets])
	})

}, [])

console.log(totalIncome, totalTickets)


  if(!admin){
    return <></>
  }

  return (
    <>
      <div className="content-wrapper">
          <div className="row">
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-primary text-white text-center card-shadow-primary">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total Tickets</h6>
                  <h2 className="mb-0">{ totalTickets }</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-danger text-white text-center card-shadow-danger">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total Reserved</h6>
                  <h2 className="mb-0">{totalTicketsReserved}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-warning text-white text-center card-shadow-warning">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total sold</h6>
                  <h2 className="mb-0">{totalSoldTickets}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 grid-margin stretch-card">
              <div className="card bg-gradient-info text-white text-center card-shadow-info">
                <div className="card-body">
                  <h6 className="font-weight-normal">Total Income</h6>
                  <h2 className="mb-0">{ totalIncome }₾</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h6 className="card-title">დარეზერვებული ({ reservedList.length })</h6>
                  </div>
                  <div className="activity_wrapper">
                      { reservedList && reservedList.map(item => (
                        <div className="list d-flex align-items-center border-bottom pb-3" key={item.id}>
                          <div>
                              <img className="img-sm rounded-circle" style={{ objectFit: 'cover', width: '64px', height: '64px' }} src={item.image} alt="" />
                          </div>
                          <div className="wrapper w-100 ml-3 my-2">
                            <p style={{marginBottom: '4px'}}><strong>{item.username} - {item.email}</strong></p>
                            <p>{ item.title }</p>
                            <small className="text-muted">აქტიურია : {item.valid_until}</small>
                          </div>
                        </div>
                      )) }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h6 className="card-title">ბოლოს გაყიდული </h6>
                  </div>
                  <div className="activity_wrapper">
                      { soldTicketsList && soldTicketsList.map(item => (
                        <div className="list d-flex align-items-center border-bottom pb-3" key={item.id}>
                          <div>
                              <img className="img-sm rounded-circle" style={{ objectFit: 'cover', width: '64px', height: '64px' }} src={item.event.image} alt="" />
                          </div>
                          <div className="wrapper w-100 ml-3 my-2">
                            <p style={{marginBottom: '4px'}}><strong>ფასი - {item.ticket.event_ticket.price}</strong></p>
                            <p>{ item.event.title }</p>
                            <small className="text-muted">გაიყიდა : {item.sold_at}</small>
                          </div>
                        </div>
                      )) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default TicketsScreen;
